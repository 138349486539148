<template>
  <div class="container_box details_box_styles">
    <div class="basic_information_box">
      <Card :bordered="false" dis-hover>
        <template #title>
          <h2 class="title_box">{{ $t('key1000216') }}</h2>
        </template>
        <detailLog ref="detailLog" :from="from" :pickingId="params.pickingId" :showNotes="showNotes"></detailLog>
      </Card>
    </div>
  </div>
</template>
<script>
import { alias47916751af154eb5b47cd5de9d34633d } from '@/customFolder/customVueAlias.js';

import api from '@/api/';
import Mixin from '@/components/mixin/common_mixin';
import detailLog from '@/views/supplier/components/wms/common/detailLog';

export default {
  props: ['operationLog', 'identification', 'from', 'params', 'falgType'],
  mixins: [Mixin],
  components: {
    detailLog
  },
  computed: {
    ymsMerchant () { // 如果当前用户是云仓供应商隐藏相关的操作功能
      let erpConfig = this.$store.state.erpConfig;
      if (erpConfig) {
        if (erpConfig.allYmsMerchantIds && erpConfig.allYmsMerchantIds.length > 0) {
          return erpConfig.allYmsMerchantIds.indexOf(erpConfig.merchant.merchantId) > -1;
        } else {
          return false;
        }
      }
    }
  },
  data () {
    return {
      form: { addNotes: '' },
      showNotes: 1,
      ruleValidate: {
        addNotes: [
          {
            type: 'string',
            max: 100,
            message: alias47916751af154eb5b47cd5de9d34633d.t('key1004056'),
            trigger: 'blur'
          }
        ]
      },
      userInfoMap: []
    };
  },
  methods: {
    showNoteFn (bool) {
      this.$nextTick(() => {
        this.$refs.detailLog.On_searchOrderLogInit();
      });
    }, // 入库单详情新增日志
    inStockDetailAdd () {
      var v = this;
      var obj = {
        relatedBusinessNo: v.params.inWareOrderId, // 相关业务编号
        receiptNo: v.params.receiptNo, // 入库单ID
        logContentDesc: v.form.addNotes, // 输入的备注信息
        logTypeDesc: '1'
      };
      this.$Spin.show();
      v.axios.post(api.add_Notes, obj).then(res => {
        this.$Spin.hide();
        if (res.data.code === 0) {
          v.form.addNotes = ''; // 输入框置空
          v.$Message.success(alias47916751af154eb5b47cd5de9d34633d.t('key1004057'));
          v.$refs.detailLog.On_searchOrderLogInit();
          this.$emit('addSuccessBack');
        } else {
          v.$Message.warning(alias47916751af154eb5b47cd5de9d34633d.t('key1004058'));
        }
      }).catch(() => {
        this.$Spin.hide();
      });
    }, // 出库详情新增日志
    outStockDetailAdd () {
      let v = this;
      let obj = {
        pickingId: this.params.pickingId,
        relatedBusinessNo: this.params.relatedBusinessNo,
        logContentDesc: this.form.addNotes // 输入的备注信息
      };
      this.$Spin.show();
      this.axios.post(api.add_wmsPicking_createLog, obj).then(res => {
        this.$Spin.hide();
        if (res.data.code === 0) {
          this.$Message.success(alias47916751af154eb5b47cd5de9d34633d.t('key1000393'));
          this.form.addNotes = '';
          v.$refs.detailLog.On_searchOrderLogInit();
          this.$emit('addSuccessBack');
        } else {
          this.$Message.warning({
            content: alias47916751af154eb5b47cd5de9d34633d.t('key1004059'),
            duration: 3
          });
        }
      }).catch(() => {
        this.$Spin.hide();
      });
    }, // 商品加工新增备注
    productProcessAddMsg () {
      let v = this;
      let obj = {
        logContentDesc: this.form.addNotes,
        workingNo: this.params.workingNo, // this.detailObj.workingNo,
        relatedBusinessNo: this.params.workingNo // this.detailObj.workingNo
      };
      this.$Spin.show();
      this.axios.post(api.createWorkingLog, obj).then(res => {
        this.$Spin.hide();
        if (res.data.code === 0) {
          this.$Message.success(alias47916751af154eb5b47cd5de9d34633d.t('key1000393'));
          v.$refs.detailLog.On_searchOrderLogInit();
          this.$emit('addSuccessBack');
        } else {
          this.$Message.warning({
            content: alias47916751af154eb5b47cd5de9d34633d.t('key1004059'),
            duration: 3
          });
        }
      }).catch(() => {
        this.$Spin.hide();
      });
    }, // 手动添加备注
    addMsg () {
      var v = this;
      this.$refs['form'].validate(valid => {
        if (valid) {
          if (!v.form.addNotes) {
            this.$Message.warning({
              content: alias47916751af154eb5b47cd5de9d34633d.t('key1002933'),
              duration: 4
            });
            return;
          }
          if (this.from === 'inStock') {
            this.inStockDetailAdd();
          } else if (this.from === 'outStock') {
            this.outStockDetailAdd();
          } else if (this.from === 'productProcess') {
            this.productProcessAddMsg();
          }
        }
      });
    }
  }
};
</script>
